import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CountAtmByPartner } from "../../../admin/partner/interfaces/count-partner-by-atm.interface";
import { PartnerPayouts } from "../../../admin/partner/interfaces/partner-payouts.interface";
import { PartnerDetailsStateService } from "../../../admin/partner/services/partner-details.state.service";

@Component({
    selector: "bo-file-upload",
    templateUrl: "./file-upload.component.html",
    styleUrls: ["./file-upload.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadComponent implements OnInit, OnDestroy {
    @Output() selectFileEvent = new EventEmitter<FileList>();
    @Output() removeFileEvent = new EventEmitter<number>();
    @Output() changeAutoApproveEvent = new EventEmitter<boolean>();
    @Output() changeAtmsStatusEvent = new EventEmitter<number>(); // enable / disable atms
    @Input() count: CountAtmByPartner;
    @Input() payouts: PartnerPayouts[] = [];
    @Input() title: string;
    @Input() action: string;
    @Input() changeStatusBtn: boolean;
    @Input() disabledAction = false;
    @Input() selectedFiles: FileList[] = [];
    @Input() autoApproveUI = false;
    autoApprove = false;

    private unsubscribe$ = new Subject<void>();

    constructor(private partnerDetailsState: PartnerDetailsStateService) {
    }

    ngOnInit() {
        this.partnerDetailsState.clearFileUpload$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(_ => this.removeFile(0));
    }

    selectFile(event: Event): void {
      const target: HTMLInputElement = event.target as HTMLInputElement;
      const file = target.files?.[0];
  
      if (file) {
          const reader = new FileReader();
  
          reader.onload = () => {
              const arrayBuffer = reader.result as ArrayBuffer;
  
              const textDecoder = new TextDecoder('windows-1252', { fatal: true });
              const decodedContent = textDecoder.decode(arrayBuffer);
  
              const utf8Encoder = new TextEncoder();
              const utf8Bytes = utf8Encoder.encode(decodedContent);
              const utf8Blob = new Blob([utf8Bytes], { type: file.type });
              const utf8File = new File([utf8Blob], file.name, { type: file.type });
  
              const dataTransfer = new DataTransfer();
              dataTransfer.items.add(utf8File);
              this.selectFileEvent.emit(dataTransfer.files);
          };
  
          reader.readAsArrayBuffer(file);
      }
  }
  
    removeFile(index: number): void {
        this.selectedFiles.splice(index);

        this.removeFileEvent.emit(index);
    }

    changeAtmsStatus(): void {
        this.changeAtmsStatusEvent.emit(this.count.unverified);
    }

    changeAutoApprove(event: MatCheckboxChange): void {
        this.autoApprove = event.checked;

        this.changeAutoApproveEvent.emit(this.autoApprove);
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
